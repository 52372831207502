.admin-goal-set-body {
    background-color: white;
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.admin-goal-set-back-wrapper {
    align-self: flex-start;
}

.admin-goal-set-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 20px;
}

.admin-goal-set-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 30px;
}

.admin-goal-set-flex-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
}

.admin-goal-set-input {
    width: 500px;
    height: auto;
    font-size: 18px;
    margin-top: 50px;
    overflow: hidden;
    overflow-y: scroll;
    resize: none;
    min-height: 200px;
    max-height: 200px;
    margin-right: 50px;
    padding: 10px;
}

.admin-goal-set-input:read-only {
    background-color: #f0f0f0;
    border-color: #dcdcdc;
    cursor: default;
}

.admin-goal-set-input::-webkit-scrollbar {
    display: none;
}

.admin-goal-set-input {
    scrollbar-width: none;
}

.admin-goal-set-confirmed {
    width: 500px;
    height: auto;
    min-height: 105px;
    max-height: 500px;
    overflow: hidden;
    resize: none;
    font-size: 18px;
    margin-top: 35px;
    margin-right: 50px;
    border: 1px solid black;
    border-radius: 5px;
    padding: 10px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
}

.admin-goal-set-button-container {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
}

.admin-goal-set-button {
    background-color: #6908C9;
    color: white;
    width: 150px;
    height: 50px;
    font-size: 18px;
    border-radius: 5px;
    border: 1px solid black;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 50px;
    margin-right: 15px;
}

.admin-goal-set-button:hover {
    background-color: #d1c4e9;
    color: white;
    border: 1px solid black;
}

.admin-goal-set-confirm-button {
    background-color: #6908C9;
    color: white;
    width: 150px;
    height: 50px;
    font-size: 18px;
    border-radius: 5px;
    border: 1px solid black;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-right: 15px;
    margin-top: 30px;
}

.admin-goal-set-confirm-button:hover {
    background-color: #d1c4e9;
    color: white;
    border: 1px solid black;
}

.admin-goal-set-edit-button {
    background-color: #6908C9;
    color: white;
    width: 150px;
    height: 50px;
    font-size: 18px;
    border-radius: 5px;
    border: 1px solid black;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-right: 15px;
    margin-top: 30px; /* Adjusted to center vertically with the textarea */
}

.admin-goal-set-edit-button:hover {
    background-color: #d1c4e9;
    color: white;
    border: 1px solid black;
}

.admin-goal-delete-button {
    background-color: #eddef8;
    color: black;
    width: 150px;
    height: 50px;
    font-size: 18px;
    border-radius: 5px;
    border: 1px solid black;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 30px;
    margin-right: 15px;
}

.admin-goal-delete-button:hover {
    background-color: #d1c4e9;
    color: white;
    border: 1px solid black;
}

.admin-goal-set-button-wrapper {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    align-items: center;
    justify-content: center;
}

.admin-goal-set-confirm-button,
.admin-goal-delete-button {
    margin-top: 5px;
}

.admin-goal-set-add-button {
    background-color: #6908C9;
    color: white;
    width: 150px;
    height: 50px;
    font-size: 18px;
    border-radius: 5px;
    border: 1px solid black;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 50px;
}

.admin-goal-set-add-button:hover {
    background-color: #d1c4e9;
    color: white;
    border: 1px solid black;
}

.admin-goal-set-review-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 80%;
}

.admin-goal-set-review-list-edit {
    list-style-type: none; /* This removes the bullet points from the list */
}

.admin-goal-set-review-text {
    font-size: 18px;
    width: 600px;
}

.admin-company-goal-set-review-text {
    font-size: 18px;
    width: 500px;
    text-indent: -40px;
}

.admin-goal-set-review-list-text {
    font-size: 18px;
    width: 600px;
}

.admin-goal-set-list-item {
    font-size: 18px;
    list-style-type: none;
    padding: 2px;
    text-indent: -50px;
}

.admin-goal-set-list-item:before {
    content: "\2605";
    color: #d1c4e9;
    padding-right: 8px;
}

.admin-goal-set-done-button {
    background-color: #6908C9;
    color: white;
    width: 150px;
    height: 50px;
    font-size: 18px;
    border-radius: 5px;
    border: 1px solid black;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.admin-goal-set-done-button:hover {
    background-color: #d1c4e9;
    color: white;
    border: 1px solid black;
}

.admin-goal-set-button-review-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 15px; /* Added to create 15px separation between buttons */
}

.admin-goal-set-submit-button {
    background-color: #eddef8;
    color: black;
    width: 150px;
    height: 50px;
    font-size: 18px;
    border-radius: 5px;
    border: 1px solid black;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 50px;
}

.admin-goal-set-submit-button:hover {
    background-color: #d1c4e9;
    color: white;
    border: 1px solid black;
}

.admin-goal-set-link {
    background: none;
    color: #6908C9;
    text-decoration: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
    padding: 0;
    margin-left: 75px;
    margin-right: 75px;
    margin-top: 50px;
}

.admin-goal-set-link:hover {
    color: #d1c4e9; /* Change color on hover */
}

.admin-goal-set-submit-link {
    background: none;
    color: #6908C9;
    text-decoration: none;
    font-weight: bold;
    border: none;
    cursor: pointer;
    font-size: 20px;
    padding: 0;
    margin-left: 75px;
    margin-right: 75px;
    margin-top: 50px;
}

.admin-goal-set-submit-link:hover {
    color: #d1c4e9;
}



.contact-body {
    background-color: white;
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.contact-body h1 {
    margin-top: 50px;
    margin-left: 5px;
    font-size: 30px;
    align-self: center;
}

.contact-container {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    padding-left: 250px;
    padding-right: 250px;
}

.contact-box {
    width: 352px;
    height: 250px;
    border: 1px solid #ccc; /* Optional: Add a border for visual separation */
    padding: 50px;
    margin: 50px;
    background-color: #f9f9f9; /* Optional: Background color */
    cursor: default; /* Ensure the box itself is not clickable */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between; /* Ensure space is distributed evenly */
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    position: relative;
    border-radius: 5px; /* Added rounded corners */
    transition: background-color .3s, color .3s;
}

.contact-box p {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 16px;
    color: #666;
}

.contact-h3-container {
    display: flex; /* Use flexbox for centering */
    justify-content: center; /* Center horizontally */
    width: 100%; /* Ensure it takes full width */
    color: black;
    margin-top: 50px;
}

.contact-h3-container a {
    color: #6908C9;
    text-decoration: none;
}

.contact-h3-container a:hover {
    color: #d1c4e9;
}

.contact-h3 {
    align-self: center;
}

.contact-button {
    background-color: #6908C9; /* Button background color */
    color: white; /* Button text color */
    text-decoration: none;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    width: 150px; /* Set a fixed width for the button */
    height: 50px; /* Set a fixed height for the button */
    margin-bottom: 20px;
    font-size: 18px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    flex-shrink: 0; /* Prevent the button from shrinking */
}

.contact-button:hover {
    background-color: #d1c4e9; /* Darker shade on hover */
    border: 1px solid black;

   }

.contact-button:hover a {
    color: white;
    text-decoration: none;
}

.contact-button a {
    color: white; /* Inherit button text color */
    text-decoration: none; /* Remove underline */
    cursor: pointer; /* Ensure the cursor is a pointer */
}

.contact-box h2 {
    margin-bottom: 5px;
}

.contact-box p {
    margin-top: 0;
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    .contact-container {
        flex-direction: column; /* Change to column layout */
        width: 100%;
        margin: 0;
        padding: 0;
        align-items: center;
    }

    .contact-body h1 {
        font-size: 24px; /* Reduce font size for mobile */
        margin-top: 30px; /* Adjust margin for mobile */
        align-self: center;
    }

    .contact-box {
        width: 80%; /* Make boxes full width on mobile */
        margin: 20px 0; /* Adjust margin for mobile */
        padding: 0;
    }

    .contact-h3-container h3 {
        font-size: 16px; /* Adjust font size for better readability */
        margin-top: 20px; /* Adjust margin for mobile */
        align-self: center;
    }

    .contact-button {
        width: 40%; /* Make buttons full width on mobile */
        min-width: 100px;
        margin-bottom: 10px; /* Adjust margin for mobile */
    }

    .contact-h3 {
        text-align: center;
    }
}

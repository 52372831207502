.back-button {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    padding: 12px 20px;
    margin: 20px;
    color: #6908C9;
    font-size: 16px;
    font-weight: 500;
    transition: all 0.2s ease;
    border-radius: 8px;
}

.back-button:hover {
    background-color: #f5f0ff;
}

.back-arrow {
    font-size: 20px;
    line-height: 1;
}

.back-text {
    font-family: 'Literata', serif;
}

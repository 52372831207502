.team-members-body {
    background-color: white;
    height: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin: 0;
}

.team-members-back-wrapper {
    align-self: flex-start;
}

.team-members-header {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
}

.team-members {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 50px;
    margin-top: 50px;
}

.team-member {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
}

.team-member-name {
    display: inline-block;
    font-size: 18px;
    font-weight: bold;
    margin-top: 0px;
    margin-bottom: 10px;
    text-decoration: none;
    cursor: pointer;
}

.team-member-name:hover {
    color: #d1c4e9;
    cursor: pointer;
}

.team-member-job-title {
    font-size: 16px;
    margin-top: 0px;
    margin-bottom: 10px;
    text-decoration: none;
}

.team-member-email {
    font-size: 16px;
    margin-top: 0px;
    margin-bottom: 10px;
    text-decoration: none;
}

.back-link {
    display: none;
}



.billing-container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background-color: white;
    height: 100%;
    margin: 0;
    min-height: 100vh;
}

.account-info, .usage-info {
    width: 45%;
    height: 300px;
    background: #f9f9f9;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2 {
    margin-bottom: 10px;
}

.account-buttons {
    display: flex;
    flex-direction: row;
}

.goal-set-button {
    background-color: #6908C9;
    color: white;
    width: 150px;
    height: 50px;
    font-size: 18px;
    border-radius: 5px;
    border: 1px solid black;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-right: 15px;
}

.goal-set-button:hover {
    background-color: #d1c4e9;
    color: white;
    border: 1px solid black
}

.billing-button {
    background-color: #6908C9;
    color: white;
    width: 150px;
    height: 50px;
    padding: 10px;
    font-size: 18px;
    border-radius: 5px;
    border: 1px solid black;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-right: 15px;
}

.billing-button:hover {
    background-color: #d1c4e9;
    color: white;
    border: 1px solid black
}

.usage-stats {
    margin-top: 20px;
}

.progress-bar {
    background: #e0e0e0;
    border-radius: 5px;
    height: 10px;
    margin: 5px 0;
}

.premium-progress {
    width: 7.4%; /* 37 out of 500 */
    background: #4caf50;
    height: 100%;
    border-radius: 5px;
}

.gpt-progress {
    width: 100%; /* 185 out of No Limit */
    background: #4caf50;
    height: 100%;
    border-radius: 5px;
}

.back-link {
    cursor: pointer;
    color: #6908C9;
    text-decoration: none;
    font-size: 18px;
    text-align: left;
    font-family: 'Literata', serif;
}

.back-link:hover {
    color: #d1c4e9;
    text-decoration: underline;
}


.navbar-container {
    width: 100%;
    background-color: white; /* Background color of the navbar */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional shadow */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar {
    height: 75px;
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
}

.navbar-links,
.navbar-buttons {
    display: flex;
    align-items: center;
}

.navbar-links {
    margin-left: 20px;
}

.navbar-buttons {
    margin-left: auto;
    margin-right: 20px;
}

.navbar-link-logo {
    font-size: 24px;
    font-weight: bold;
    font-family: "Ubuntu", "PT Sans", sans-serif;
    cursor: pointer;
    transition: color 0.3s;
    margin-left: 20px;
    color: #6908C9;
    text-decoration: none;
}

.navbar-link-logo:hover {
    color: #d1c4e9;
}

.navbar-link {
    color: black;
    text-decoration: none;
    margin-left: 20px;
    margin-right: 20px;
    font-size: 18px;
    transition: color 0.2s ease-in-out;
    font-family: "Literata", serif;
}

.navbar-link:hover {
    color: #d1c4e9;
}

.navbar-link-button {
    color: black;
    font-size: 18px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 0;
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s;
    background: none;
    border: none;
    cursor: pointer;
}

.navbar-hamburger {
    display: none; /* Hidden by default */
    flex-direction: column;
    cursor: pointer;
}

.navbar-hamburger-line {
    width: 25px;
    height: 3px;
    background-color: #6908C9; /* Color of the hamburger lines */
    margin: 4px 0;
    transition: 0.4s;
}

/* Sidebar styles */
.navbar-sidebar {
    position: fixed;
    top: 0;
    left: -250px; /* Hide sidebar off-screen */
    width: 250px;
    height: 100%;
    background-color: #f9f9f9;
    transition: left 0.3s ease;
    z-index: 1000; /* Ensure it appears above other content */
}

.navbar-sidebar.open {
    left: 0; /* Show sidebar */
}

.navbar-close-btn {
    background: none;
    border: none;
    font-size: 30px;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 10px;
}

.navbar-sidebar-link {
    display: block;
    padding: 15px;
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.navbar-sidebar-link:hover {
    background-color: #d1c4e9; /* Optional hover effect */
}

.navbar-sidebar-link-button {
    display: block;
    width: 100%;
    text-align: left;
    font-size: 15px;
    font-family: "Literata", serif;
    background: none;
    border: none;
    padding: 15px;
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.navbar-sidebar-link-button:hover {
    background-color: #d1c4e9;
}

/* Media query for mobile */
@media (max-width: 768px) {
    .navbar-hamburger {
        display: flex; /* Show hamburger icon on mobile */
        margin-left: 20px;
    }

    .navbar-links {
        display: none; /* Hide links by default on mobile */
    }

    .navbar-buttons {
        display: none; /* Hide buttons by default on mobile */
    }
}

.progress-page-body {
    background-color: white;
    height: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.progress-page-back-wrapper {
    align-self: flex-start;
}

.progress-page-content {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.progress-page-header {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
}

.progress-page-header-text {
    text-align: center;
}

.progress-page-goals {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.progress-page-goal-name {
    font-size: 20px;
    margin-bottom: 10px;
    max-width: 500px;
}

.progress-page-goal-progress-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 750px;
}

.progress-page-goal-progress-percentage-container {
    display: flex;
    align-items: center;
}

.progress-page-goal-progress {
    width: 500px;
    accent-color: #6908C9;
}

.progress-page-goal-comment {
    width: 500px;
    height: 100px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 5px;
    box-sizing: border-box;
}

.progress-page-goal-comment:read-only {
    background-color: #f0f0f0;
    border-color: #dcdcdc;
    cursor: default;
}

.progress-page-goal-comment-display {
    margin-top: 5px;
    max-width: 500px;
    word-wrap: break-word;
}

.progress-page-goal-comment-button {
    background-color: #6908C9;
    color: white;
    width: 150px;
    height: 50px;
    font-size: 18px;
    border-radius: 5px;
    border: 1px solid black;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 10px;
    margin-left: 15px;
}

.progress-page-goal-comment-button:hover {
    background-color: #d1c4e9;
    color: white;
    border: 1px solid black;
}

.progress-page-goal-progress-percentage {
    width: 50px;
    text-align: right;
}

.progress-page-goal-confirm-button {
    background-color: #6908C9;
    color: white;
    width: 150px;
    height: 50px;
    font-size: 18px;
    border-radius: 5px;
    border: 1px solid black;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-left: 79px;
    margin-top: 15px;
}

.progress-page-goal-confirm-button:hover {
    background-color: #d1c4e9;
    color: white;
    border: 1px solid black;
}

.progress-page-goal-comment-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.progress-page-submit-container {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 100px;
}

.progress-page-goal-text-toggle-button {
    background-color: #6908C9;
    color: white;
    width: 150px;
    height: 30px;
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid black;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 5px;
}

.progress-page-goal-text-toggle-button:hover {
    background-color: #d1c4e9;
    color: white;
    border: 1px solid black;
}

.progress-page-goal-text {
    margin-top: 5px;
    max-width: 500px;
    font-size: 16px;
    color: #333;
}

.progress-page-submit-link {
    background: none;
    color: #6908C9;
    text-decoration: none;
    font-weight: bold;
    border: none;
    cursor: pointer;
    font-size: 20px;
    padding: 0;
}

.progress-page-submit-link:hover {
    color: #d1c4e9;
}

@media (max-width: 768px) {
    .progress-page-goal-progress-container {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }

    .progress-page-goal-progress {
        width: 100%;
    }

    .progress-page-goal-comment-button {
        width: 25%;
        margin-left: 0;
        margin-top: 10px;
    }
}

.progress-page-goal-comments-list ul {
    list-style: none;
    padding-left: 0;
    margin: 10px 0;
    max-width: 500px;
}

.progress-page-goal-comment-item {
    position: relative;
    padding-left: 50px;
    text-indent: -50px;
    margin-bottom: 10px;
}

.progress-page-goal-comment-item:before {
    content: "\2605";
    color: #d1c4e9;
    padding-right: 8px;
}

.progress-page-goal-comment-item p {
    margin: 0;
    word-wrap: break-word;
    display: inline;
}
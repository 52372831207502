.employee-profile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.employee-profile-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 20px;
    min-height: 40vh;
    align-items: stretch;
}

.employee-card {
    background-color: #fff; /* Card background color */
    border: 1px solid #ccc; /* Card border */
    border-radius: 8px; /* Rounded corners */
    padding: 10px; /* Inner spacing */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Shadow effect */
    margin-left: 20px;
    width: 35%;
}

.employee-card-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 250px;
    gap: 40px;
}

.employee-card-edit-link {
    background: none;
    color: #6908C9;
    text-decoration: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
    padding: 0;
}

.employee-card-edit-link:hover {
    color: #d1c4e9;
}

.employee-info {
    background-color: #fff; /* Info box background color */
    border: 1px solid #ccc; /* Info box border */
    border-radius: 8px; /* Rounded corners */
    padding: 10px; /* Inner spacing */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Shadow effect */
    width: 65%;
    margin-right: 20px;
}

.employee-goals-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 60vh;
    align-items: stretch;
}

.employee-goals {
    border: 1px solid #ccc; /* Info box border */
    border-radius: 8px; /* Rounded corners */
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Shadow effect */
    margin: 20px;
    height: 100%;
}

.careers-body {
    background-color: white;
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.careers-title {
    font-size: 30px;
    margin-top: 50px;
    margin-bottom: 0px;
    color: black;
    text-align: center;
}

.careers-container {
    max-width: 800px;
    margin: 30px auto; /* Center the container horizontally and add top margin */
    justify-content: center;
    align-items: center;
    padding: 20px;
    border: 1px solid #ccc;
    /* font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
    color: #333;
    background-color: #f9f9f9;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.careers-section {
    margin-bottom: 30px;
}

.careers-subtitle {
    font-size: 25px;
    margin-bottom: 10px;
    color: black;
    padding: 0px;
}

.careers-text {
    font-size: 20px;
    line-height: 1.6;
}

.careers-link {
    color: #6908c9;
    text-decoration: none;
    transition: color 0.3s;
}

.careers-link:hover {
    color: #d1c4e9;
}

.careers-dropdown {
    width: 20%;
    padding: 10px;
    margin-bottom: 10px;
    font-size: 15px;
}


.employee-portal {
    display: flex;
    font-family: 'Literata', serif;
}

.employee-portal-sidebar {
    width: 200px;
    background-color: #f4f4f4;
    padding: 15px;
    height: 100vh;
    padding-top: 20px;
    transition: left 0.3s ease;
    position: relative;
}

.employee-portal-sidebar-button {
    font-family: 'Literata', serif;
    font-size: 18px;
    border: none;
    background-color: transparent;
    display: flex;
    padding: 0;
    text-decoration: none;
    color: #333;
    margin-left: 5px;
    margin-bottom: 25px;
    width: 100%;
    justify-content: left;
    align-items: left;
    cursor: pointer;
}

.employee-portal-sidebar-button:hover {
    color: #d1c4e9;
}

.employee-portal-content {
    flex-grow: 1;
    padding: 20px;
    padding-top: 0px;
}

.employee-portal-menu-button {
    display: none;
    font-size: 18px;
    font-family: 'Literata', serif;
    cursor: pointer;
    margin: 10px;
    background: none;
    border: none;
    color: #6908C9;
    transition: color 0.3s;
}

.employee-portal-menu-button:hover {
    color: #d1c4e9;
}

.employee-portal-sidebar.mobile {
    display: none;
    position: fixed;
    top: 0;
    left: -250px;
    width: 200px;
    height: 100%;
    background-color: #f4f4f4;
    transition: left 0.3s ease;
    z-index: 1000;
}

.employee-portal-sidebar.mobile.open {
    left: 0;
}

/* Media query for mobile */
@media (max-width: 768px) {
    .employee-portal-menu-button {
        display: flex;
        margin-top: 20px;
    }

    .employee-portal-sidebar {
        display: none;
    }

    .employee-portal-sidebar.mobile {
        display: block;
    }
}

.goal-setting-button {
    background-color: #6908C9;
    color: white;
    width: 150px;
    height: 50px;
    font-size: 18px;
    border-radius: 5px;
    border: 1px solid black;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 10px;
}

.goal-setting-button:hover {
    background-color: #d1c4e9;
    color: white;
    border: 1px solid black;
}

.goal-setting-button-container {
    display: flex;
    gap: 15px;
}
.content {
  display: flex;
  flex-flow: column nowrap;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  width: 100%;
}

body{
  font-family: "Literata", "PT Sans", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-style: normal;
}

p {
  font-family: "Literata", sans-serif;
  font-weight: 400;
  font-style: normal; 
}

button {
  font-family: "Literata", sans-serif;
}

textarea {
  border-radius: 5px;
  font-size: 16px;
  font-family: "Literata", sans-serif;
}
.about-body {
    background-color: white;
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.about-body h1 {
    margin-top: 50px;
    font-size: 30px;
    align-self: center;
}

.mission, .story, .call-to-action {
    padding: 20px;
    margin: 20px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.mission {
    background-size: cover; /* Ensures the image covers the entire section */
    background-position: center; /* Centers the image */
    background-image: linear-gradient(to bottom, rgba(237, 222, 248, 0.7) 0%, rgba(255, 255, 255, 0.5) 50%, rgba(237, 222, 248, 0.7) 100%),
                      url("https://images.unsplash.com/photo-1522124624696-7ea32eb9592c?q=1000&w=2669&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
    background-blend-mode: overlay; /* Blend the background image with the gradient */
    background-color: #eddef8;
}

.about-section-header {
    font-size: 2rem;
    font-weight: 600;
}

.about-section-text {
    font-size: 1rem;
    line-height: 1.6;
    text-align: justify;
    padding-left: 100px;
    padding-right: 100px;
}

.about-section-text-italic {
    font-style: italic;
}

.about-section-list {
    font-size: 1rem;
    line-height: 1.5;
    text-align: left;
    padding-left: 20px;
    list-style-type: none;
    padding: 0;
}

.about-section-list-item:before {
    content: "\2605";
    margin-right: 8px;
    color: #d1c4e9;
}

.story {
    background-color: #f9f9f9;
}

.call-to-action {
    background-size: cover;
    background-position: center;
    background-image: linear-gradient(to bottom, rgba(237, 222, 248, 0.7) 0%, rgba(255, 255, 255, 0.5) 50%, rgba(237, 222, 248, 0.7) 100%),
    url("https://images.unsplash.com/photo-1419242902214-272b3f66ee7a?q=80&w=1813&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
    background-blend-mode: overlay;
    background-color: #eddef8;
}

.about-section {
    height: 75vh; /* Each section takes up the full viewport height */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
    padding: 20px;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth; /* Smooth scrolling between sections */
}

/* Optional: Style for header and footer to ensure they fit well */
.about-header, .about-footer {
    text-align: center;
}

.about-header {
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.about-sign-up-button {
    padding: 12px 24px;
    margin-top: 10px;
    font-size: 18px;
    border: 1px solid black;
    border-radius: 5px;
    background-color: #f9f9f9;
    color: black;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.about-sign-up-button:hover {
    background-color: #d1c4e9;
    color: white;
}

/* Universal Media Query for Mobile Devices */
@media (max-width: 768px) {
   .about-section {
    height: auto;
    min-height: 100vh;
   }

   .about-section-header {
    font-size: 1.5rem;
    text-align: center;
   }

   .about-section-text {
    padding-left: 20px;
    padding-right: 20px;
    text-align: left;
   }

   .about-section-list {
       padding-left: 0; /* Remove left padding from the list */
       list-style-type: none; /* Remove default bullets */
       padding: 0; /* Reset padding */
   }

   .about-section-list-item {
       position: relative; /* Position relative for custom bullet */
       padding-left: 30px; /* Space for custom bullet */
       margin-bottom: 10px; /* Space between list items */
   }

   .about-section-list-item:before {
       content: "\2605"; /* Custom bullet */
       position: absolute; /* Position absolute to align with text */
       left: 0; /* Align bullet to the left */
       color: #d1c4e9; /* Bullet color */
       line-height: 1.5; /* Align bullet with text */
   }

   .mission {
    background-size: cover; /* Ensures the image covers the entire section */
    background-position: center; /* Centers the image */
    background-image: linear-gradient(to bottom, rgba(237, 222, 248, 0.7) 0%, rgba(255, 255, 255, 0.5) 50%, rgba(237, 222, 248, 0.7) 100%),
                      url("https://images.unsplash.com/photo-1522124624696-7ea32eb9592c?q=1000&w=2669&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
    background-blend-mode: overlay; /* Blend the background image with the gradient */
    background-repeat: no-repeat;
    background-color: #eddef8;
}
   .call-to-action {
    background-size: cover;
    background-position: center;
    background-image: linear-gradient(to bottom, rgba(237, 222, 248, 0.7) 0%, rgba(255, 255, 255, 0.5) 50%, rgba(237, 222, 248, 0.7) 100%),
    url("https://images.unsplash.com/photo-1419242902214-272b3f66ee7a?q=80&w=1813&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
    background-blend-mode: overlay;
    background-repeat: no-repeat;
    background-color: #eddef8;
}
}
/* SalesQuotePage.css */

.sales-quote-container {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    /* justify-content: center; */
    margin-top: 100px;
    align-items: center;
    height: 100vh; /* Full viewport height */
    background-color: white; /* Light background color */
    
}

.sales-quote-header {
    font-size: 24px; /* Larger font size for the header */
    margin-bottom: 10px; /* Space below the header */
    text-align: center; /* Center the header text */
    color: black;
}

.sales-quote-description {
    font-size: 16px; /* Smaller font size for the description */
    margin-bottom: 20px; /* Space below the description */
    text-align: center; /* Center the description text */
    color: black;
}

.sales-quote-form {
    background-color: #f9f9f9; /* White background for the form */
    padding: 20px;
    margin-top: 20px;
    border: 1px solid #ccc;
    border-radius: 5px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    width: 100%;
    max-width: 400px; /* Maximum width of the form */
    box-sizing: border-box; /* Ensure padding is included in total width */
    transition: transform 0.2s;
    transition: background-color .3s, color .3s;
}

.sales-quote-form div {
    margin-bottom: 15px;
}

.sales-quote-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.sales-quote-form input,
.sales-quote-form textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box; /* Ensure padding is included in total width */
}

.sales-quote-form button {
    width: 100%;
    padding: 10px;
    background-color: #6908C9;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    box-sizing: border-box; /* Ensure padding is included in total width */
    transition: background-color 0.3s ease;
}

.sales-quote-form button:hover {
    background-color: #d1c4e9;
    color: black;
}
.newsletter-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.newsletter-centered-message {
    text-align: center;
    margin-top: 10px;
}

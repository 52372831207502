.edit-employee-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.edit-employee-header {
    font-size: 24px;
    margin-bottom: 10px;
    text-align: center;
    color: black;
}

.edit-employee-form {
    background-color: #f9f9f9;
    padding: 20px;
    margin-top: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 600px;
    box-sizing: border-box;
}

.edit-employee-form div {
    margin-bottom: 15px;
}   

.edit-employee-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.edit-employee-form input,
.edit-employee-form textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    font-size: 16px;
}

.edit-employee-form textarea {
    height: 150px;
}


.edit-employee-form button {
    width: 100%;
    padding: 10px;
    background-color: #6908C9;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    box-sizing: border-box;
    transition: background-color 0.3s ease;
}

.edit-employee-form button:hover {
    background-color: #d1c4e9;
    color: black;
}

.edit-employee-delete-button {
    background-color: #e60d0d;
    color: white;
    width: 150px;
    height: 50px;
    border: 1px solid white;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
    box-sizing: border-box;
    transition: background-color 0.3s ease;
    margin-top: 20px;
    margin-bottom: 20px;
}

.edit-employee-delete-button:hover {
    background-color: #ff0000;
    color: black;
    border: 1px solid black;
}

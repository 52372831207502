.astellyst-ai-button {
    margin-top: 30px;
    background-color: #eddef8;
    color: #6908C9;
    width: 150px;
    height: 50px;
    font-size: 18px;
    font-family: "Literata", serif;
    border-radius: 5px;
    border: 1px solid #6908C9;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.astellyst-ai-button:hover {
    background-color: #d1c4e9;
    color: white;
    border: 1px solid black;
}

/* eddef8 */
/* src/components/Footer.css */

footer {
    background-color: black;
    padding: 10px;
    text-align: center;
    color: #999;
    width: 100%; /* Change from 100vw to 100% */
    position: relative; /* or fixed if you want it to always be visible */
    left: 0; /* Ensure it starts from the left */
    box-sizing: border-box; /* Include padding in width calculation */
    margin-top: auto;
    bottom: 0;
}

.footer-link {
    color: #999;
    text-decoration: none;
}

.footer-link:hover {
    color: #d1c4e9;
}

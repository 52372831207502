.manager-goal-set-body {
    background-color: white;
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.manager-goal-set-back-wrapper {
    align-self: flex-start;
}

.manager-goal-set-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    margin-top: 100px;
}

.manager-goal-set-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 30px;
}

.manager-goal-set-flex-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
}

.manager-goal-set-input {
    width: 500px;
    height: auto;
    font-size: 18px;
    margin-top: 50px;
    overflow: hidden;
    overflow-y: scroll;
    resize: none;
    min-height: 200px;
    max-height: 200px;
    margin-right: 50px;
    padding: 10px;
}

.manager-goal-set-input:read-only {
    background-color: #f0f0f0;
    border-color: #dcdcdc;
    cursor: default;
}

.manager-goal-set-input::-webkit-scrollbar {
    display: none;
}

.manager-goal-set-input {
    scrollbar-width: none;
}   

.manager-goal-set-button-container {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
}

.manager-goal-set-button {
    background-color: #6908C9;
    color: white;
    width: 150px;
    height: 50px;
    font-size: 18px;
    border-radius: 5px;
    border: 1px solid black;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 50px;
    margin-right: 15px;
}

.manager-goal-set-button:hover {
    background-color: #d1c4e9;
    color: white;
    border: 1px solid black;
}

.manager-goal-set-confirm-button {
    background-color: #6908C9;
    color: white;
    width: 150px;
    height: 50px;
    font-size: 18px;
    border-radius: 5px;
    border: 1px solid black;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-right: 15px;
    margin-top: 30px;
}

.manager-goal-set-confirm-button:hover {
    background-color: #d1c4e9;
    color: white;
    border: 1px solid black;
}

.manager-goal-set-edit-button {
    background-color: #6908C9;
    color: white;
    width: 150px;
    height: 50px;
    font-size: 18px;
    border-radius: 5px;
    border: 1px solid black;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-right: 15px;
    margin-top: 30px; /* Adjusted to center vertically with the textarea */
}

.manager-goal-set-edit-button:hover {
    background-color: #d1c4e9;
    color: white;
    border: 1px solid black;
}

.manager-goal-delete-button {
    background-color: #eddef8;
    color: black;
    width: 150px;
    height: 50px;
    font-size: 18px;
    border-radius: 5px;
    border: 1px solid black;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 30px;
    margin-right: 15px;
}

.manager-goal-delete-button:hover {
    background-color: #d1c4e9;
    color: white;
    border: 1px solid black;
}

.manager-goal-set-button-wrapper {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    align-items: center;
    justify-content: center;
}

.manager-goal-set-confirm-button,
.manager-goal-delete-button {
    margin-top: 5px;
}

.manager-goal-set-add-button {
    background-color: #6908C9;
    color: white;
    width: 150px;
    height: 50px;
    font-size: 18px;
    border-radius: 5px;
    border: 1px solid black;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 50px;
}

.manager-goal-set-add-button:hover {
    background-color: #d1c4e9;
    color: white;
    border: 1px solid black;
}

.manager-goal-set-review-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 80%;
}

.manager-goal-review-mission {
    font-size: 16px;
    color: #504a5a;
    max-width: 750px;
}

.manager-goal-review-goals-list {
    max-width: 750px;
}

.manager-goal-set-list-item {
    font-size: 16px;
    list-style-type: none;
    padding: 2px;
    text-indent: -50px;
    max-width: 750px;
    color: #504a5a;
}

.manager-goal-set-list-item:before {
    content: "\2605";
    color: #d1c4e9;
    padding-right: 8px;
}

.manager-goal-set-button-review-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 15px; /* Added to create 15px separation between buttons */
}

.manager-goal-set-review-list-edit {
    list-style-type: none
}

.manager-goal-set-review-list-text {
    font-size: 18px;
    width: 600px;
}

.manager-goal-set-review-text {
    font-size: 18px;
    width: 500px;
    text-indent: -40px;
}

.manager-goal-set-link {
    background: none;
    color: #6908C9;
    text-decoration: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
    padding: 0;
    margin-left: 75px;
    margin-right: 75px;
    margin-top: 50px;
}

.manager-goal-set-link:hover {
    color: #d1c4e9; /* Change color on hover */
}

.manager-goal-set-submit-link {
    background: none;
    color: #6908C9;
    text-decoration: none;
    font-weight: bold;
    border: none;
    cursor: pointer;
    font-size: 20px;
    padding: 0;
    margin-left: 75px;
    margin-right: 75px;
    margin-top: 50px;
}

.manager-goal-set-submit-link:hover {
    color: #d1c4e9;
}

.loading-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
    width: 100%;
}

.loading-content {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.loading-text {
    margin-top: 20px;
    color: black;
    font-size: 1.2rem;
    font-weight: 500;

}

.resources-container h1 {
    margin-top: 50px;
    font-size: 30px;
    align-self: center;
}

.resources-container h3 {
    height: 50px;
    overflow: hidden;
}

.resources-container {
    text-align: center;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    height: 100%;
    background-color: white;
}

.resources-grid {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 120px;
}

.resource-card {
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    border-radius: 5px;
    padding: 20px;
    margin: 10px;
    width: 25%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: default;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.resource-card p {
    text-align: center;
    font-size: 16px;
    color: #666;
}

.resource-button {
    display: inline-block;
    margin-top: auto;
    background-color: #6908C9;
    border: 1px solid black;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    width: 150px;
    height: 50px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.resource-button:hover {
    background-color: #d1c4e9;
    color: white;
    border: 1px solid black;
}

.resources-section {
    height: 75vh; /* Each section takes up the full viewport height */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
    box-sizing: border-box;
    background-color: #f9f9f9;
    border: 1px solid #d1c4e9;
}

@media (max-width: 768px) {
    .resources-grid {
        flex-direction: column;
        align-items: center;
        padding: 0 20px;
    }

    .resources-section {
        height: 100%;
    }

    .resource-card {
        width: 80%;
        margin: 10px 0;
    }

    .resources-container h1 {
        font-size: 24px;
        margin-top: 30px;
    }

    .resources-container h3 {
        font-size: 18px;
        padding-bottom: 15px;
    }
}

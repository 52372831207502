.forgot-password-body {
    background-color: white;
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.forgot-password-body h1 {
    margin-top: 20px;
    margin-left: 5px;
    margin-bottom: 30px;
    font-size: 30px;
    align-self: center;
}

.forgot-password-p {
    margin-left: 5px;
    font-size: 16px;
    align-self: left;
    text-align: left;
    margin-top: 10px;
}

.forgot-password-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.forgot-password-box {
    width: 352px;
    height: 400px;
    padding: 20px;
    margin: 70px auto;
    background-color: #f9f9f9; /* Optional: Background color */
    cursor: default; /* Ensure the box itself is not clickable */
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    position: relative;
    border: 1px solid #ccc;
    border-radius: 5px; /* Added rounded corners */
    transition: background-color .3s, color .3s;
}

.forgot-password-h3-container {
    display: flex; /* Use flexbox for centering */
    justify-content: center; /* Center horizontally */
    width: 100%; /* Ensure it takes full width */
    color: black;
    margin-top: 50px;
}

.forgot-password-h3-container a {
    color: #6908C9;
    text-decoration: none;
}

.forgot-password-h3-container a:hover {
    color: #d1c4e9;
}

.forgot-password-input {
    width: 100%;
    min-width: 250px;
    line-height: 50px;
    font-size: 18px;
    font-family: 'Literata', serif;
    align-self: center;
    padding: 0;
    margin-bottom: 10px;
}

.forgot-password-button {
    background-color: #6908C9; /* Button background color */
    color: white; /* Button text color */
    border: 1px solid black;
    width: 150px; /* Set a fixed width for the button */
    height: 50px; /* Set a fixed height for the button */
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 18px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    flex-shrink: 0; /* Prevent the button from shrinking */
}

.forgot-password-button:hover {
    background-color: #d1c4e9; /* Darker shade on hover */
    border: 1px solid black;

   }

.forgot-password-button:hover a {
    color: white;
    text-decoration: none;
}

.forgot-password-button a {
    color: white; /* Inherit button text color */
    text-decoration: none; /* Remove underline */
    cursor: pointer; /* Ensure the cursor is a pointer */
}

.forgot-password-button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 30px;
}

.forgot-password-input::placeholder {
    color: #999; /* Placeholder text color */
    font-family: 'Literata', serif;
    font-size: 18px;
    text-align: left;
    padding-left: 6px;
}

.forgot-password-back-link {
    margin-top: 10px;
    cursor: pointer;
    color: #6908C9;
    text-decoration: none;
    font-size: 18px;
    text-align: center;
    font-family: 'Literata', serif;
    display: flex;
    justify-content: center;
}

.forgot-password-back-link:hover {
    color: #d1c4e9;
}
